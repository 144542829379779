import Link from "next/link";


const Footer = () => {
  return (
    <div className="w-full">
      <div className="bg-dark-1 text-white lg:px-8 xl:px-24 py-12 lg:py-12">
        <div className="flex flex-col space-y-7 lg:space-y-0 items-center lg:items-start text-center lg:text-left lg:flex-row lg:justify-between">
          <div className="mb-10 w-3/4 space-y-8 text-sm text-center lg:text-left flex flex-col items-center lg:items-start lg:w-80">
            <Link href="/"><img alt="Edulethics" className="w-40 lg:w-56 cursor-pointer" src="/logo-2.webp" /></Link>
            <div className="mx-0">Yes! We are Nigeria's No 1 Students' and Educational Resource Website</div>
            <div>
              <p className="mb-5">Join us at <b className="font-bold">EDULETHICS</b></p>
              <Link href="/signup">
                <a style={{ outline: 'none' }} className="bg-custom-blue focus-within:ring transition-all duration-200 font-semibold py-3 px-7 text-xs rounded-md">
                  Get Started
                </a>
              </Link>
            </div>
          </div>
          <div>
            <h1 className="font-bold mb-3">Quick Links</h1>
            <ul className="text-sm space-y-2">
              <li>
                <Link href="/">
                  <a className="hover:text-secondary">Home</a>
                </Link>
              </li>
              <li>
                <Link href="/about">
                  <a className="hover:text-secondary">About Us</a>
                </Link>
              </li>
              <li>
                <Link href="/#partners">
                  <a className="hover:text-secondary">Partner with us</a>
                </Link>
              </li>
              <li>
                <Link href="/support#contact">
                  <a className="hover:text-secondary">Contact Us</a>
                </Link>
              </li>
              <li>
                <Link href="/support#faq">
                  <a className="hover:text-secondary">FAQ</a>
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h1 className="font-bold mb-3">Explore</h1>
            <ul className="text-sm space-y-2">
              <li>
                <Link href="#">
                  <a className="hover:text-secondary">Primary Schools</a>
                </Link>
              </li>
              <li>
                <Link href="#">
                  <a className="hover:text-secondary">Junior Secondary Schools</a>
                </Link>
              </li>
              <li>
                <Link href="#">
                  <a className="hover:text-secondary">Senior Secondary Schools</a>
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h1 className="font-bold mb-3">Contact Us</h1>
            <ul className="text-sm text-center lg:text-left space-y-6 lg:space-y-3">
              <li className="flex flex-col space-y-2 lg:space-y-0 lg:flex-row items-center space-x-4">
                <img src="/img/phone.svg" className="w-7" alt="phone" />
                <span><a href="tel:+2349019653199">+234 901 965 3199</a></span>
              </li>
              <li className="flex flex-col space-y-2 lg:space-y-0 lg:flex-row items-center space-x-4">
                <img src="/img/mail.svg" className="w-7" alt="mail" />
                <span>support@edulethics.com</span>
              </li>
              <li className="flex flex-col space-y-2 lg:space-y-0 lg:flex-row items-center space-x-4" >
                <img src="/img/location.svg" className="w-7" alt="location" />
                <span>Lagos, Nigeria.</span>
              </li>
            </ul>
          </div>
          <div>
            <h1 className="font-bold mb-3">Follow Us</h1>
            <ul className="text-sm space-x-5 flex flex-row items-center ">
              <li className="flex flex-row items-center space-x-4">
                <a target="_blank" rel="noreferrer" href="https://facebook.com">
                  <img src="/img/facebook.svg" className="w-7" alt="facebook" />
                </a>
              </li>
              <li className="flex flex-row items-center space-x-4">
                <a target="_blank" rel="noreferrer" href="https://twitter.com">
                  <img src="/img/twitter.svg" className="w-7" alt="twitter" />
                </a>
              </li>
              <li className="flex flex-row items-center space-x-4">
                <a target="_blank" rel="noreferrer" href="https://instagram.com">
                  <img src="/img/instagram.svg" className="w-7" alt="instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-dark-2 text-white text-center p-2 text-sm">&copy; 2021. All rights reserved.</div>
    </div>
  )
}

export default Footer;
