import Footer from "./navigation/Footer";
import Header from "./navigation/Header";
import Seo from "../Seo";

const Layout = ({ children, seo }) => {
  return (
    <>
      <Seo seo={seo} />
      <div>
        <Header />
        <div id="body">
          {children}
        </div>
        <Footer />
      </div>
    </>
  )
}


export default Layout;
