import Link from "next/link";
import { useRouter } from "next/router";
import { useContext } from "react";
import { AuthContext } from "../../../lib/providers/AuthProvider";
import styles from "../../../styles/MobileNavigation.module.css";


const MobileNavigation = ({ isNavOpen, closeNav }) => {
  const router = useRouter();
  const { user } = useContext(AuthContext);
  let className = "";

  if (isNavOpen) {
    className = "bg-white w-full h-full top-0 bottom-0 py-2 fixed overflow-y-scroll overflow-x-hidden transition-transform duration-500 lg:hidden " + styles.navOpen;
  } else {
    className = "bg-white w-full h-full top-0 fixed lg:hidden " + styles.navClosed;
  }

  return (
    <div style={{zIndex: 99999}} className={className}>
      <button onClick={closeNav} style={{ outline: '1px solid white' }} className="absolute right-1 mr-3 mt-5">
        <img className="w-5" src="/img/close.svg" alt="close" />
      </button>
      <div className="flex flex-col h-full mt-0 mx-4 justify-around">
        <div>
          <Link href="/">
            <img className="w-44 my-2" src="/logo.svg" alt="Edulethics" />
          </Link>
        </div>
        <div className="flex flex-col items-start space-y-8">
          <NavLink routerPathName={router.pathname} pathName="/" name="Home" href="/" />
          <NavLink routerPathName={router.pathname} pathName="/support" name="Support" href="/support" />
          <NavLink routerPathName={router.pathname} pathName="/about" name="About Us" href="/about" />
          <NavLink routerPathName={router.pathname} pathName="#" name="Partner With Us" href="/#partners" />
          {user && <NavLink routerPathName={router.pathname} pathName="/dashboard" name="My Dashboard" href="/dashboard" />}
          {user && <NavLink routerPathName={router.pathname} pathName="/account/edit" name="Manage My Account" href="/account/edit" />}
        </div>
        <div className="flex flex-col items-center">
          {!user &&
            <>
              <Link href="/signin">
                <a className="my-5 text-primary text-lg">
                  Sign in
                </a>
              </Link>
              <Link href="/signup">
                <a className="w-full m-auto text-center border border-primary rounded-lg text-white bg-primary focus:bg-primary focus:text-white p-3 text-lg">
                  Get Started
                </a>
              </Link>
            </>
          }
        </div>
      </div>
    </div>
  )
}

const NavLink = ({ name, href, pathName, routerPathName }) => {
  let className = "";
  if (pathName === routerPathName) {
    className = "text-primary text-secondary text-2xl font-semibold";
  } else {
    className = "text-primary hover:text-secondary text-xl"
  }
  return (
    <Link href={href}>
      <a className={className}>
        {name}
      </a>
    </Link>
  )
}

export default MobileNavigation;
