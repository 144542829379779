import { useState } from "react";
import BaseHeader from "./BaseHeader";
import MobileNavigation from "./MobileNavigation";

const Header = () => {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => setNavOpen(!navOpen);

  const closeNav = () => setNavOpen(false)

  return (
    <>
      <BaseHeader toggleNav={toggleNav} />
      <MobileNavigation isNavOpen={navOpen} closeNav={closeNav} />
    </>
  )
}


export default Header;
